import React, { useState } from 'react'
import { Box, Button, Stack, TextField } from '@mui/material'
import { CDN_IMG } from '../../../../../constants/endpoints'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import { validateEmail } from './../../../../../utils/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { checkValidUser } from '../../../../../actions/auth0.actions'
import { getEmailVerification, saveEmptyUser } from '../../../../../actions/quiz.actions'
import { identifyEmptyUser, trackEvent } from '../../../../../utils/analytics'
import { variantSelector } from '../../../../../utils/selectors'
import validations from '../../../../../constants/configs/validations'
import { prepareTypographyFootnote } from '../../../../../styles/theme-proven/typography'
import { createProvenTheme } from '../../../../../styles/theme-proven'
import { patchStoreFieldsUser } from '../../../../../actions/account.actions'

const DATA_CY_PREFIX = 'sephora-in-store-my-email-container'

const themeProven = createProvenTheme()

/**
 * this wrapper is for re-styled container
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
function ContainerTocRestyled({ children }) {
  return (
    <Box
      data-testid="container-toc-restyled-testid"
      sx={{
        color: themeProven.palette.gray.darkGray,
        '& p': {
          ...prepareTypographyFootnote(themeProven),
          fontSize: '11px !important',
          marginBlockStart: 0,
          marginBlockEnd: 0
        },
        '& a': {
          color: themeProven.palette.gray.darkGray,
          textDecoration: 'underline'
        }
      }}
    >
      {children}
    </Box>
  )
}

function EnterYourEmailContainer({ onClose, storeId, onSubmit, title, toc }) {
  const dispatch = useDispatch()
  const [doubleOptInEmailValue, setDoubleOptInEmailValue] = useState(true)
  const [email, setEmail] = useState('')
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [invalidMessage, setInvalidMessage] = useState('')
  const [isProcess, setIsProcess] = useState(false)
  const variant = useSelector(variantSelector)
  const ctaText = 'SUBMIT'

  const onChangeDoubleOptInEmail = () => {
    setDoubleOptInEmailValue(!doubleOptInEmailValue)
  }

  const handleEmailChange = e => {
    const myEmail = e.currentTarget.value
    setEmail(myEmail)
  }

  const checkUserExist = async userEmail => {
    let userId
    let status = null
    try {
      const res = await checkValidUser(userEmail, true)
      userId = res._id
      status = res.status
    } catch (_err) {
      userId = false
    }
    return { userId, status }
  }

  // Kickbox Email Validation
  const isValidEmailKickbox = async email => {
    setIsValidEmail(false)
    setIsProcess(true)
    setInvalidMessage('')
    let validation = await validateEmail(email, getEmailVerification)
    let isValid = validation?.result
    setIsValidEmail(isValid)
    if (!isValid) {
      let kickBoxRejectMsg = validation?.suggestedEmail
      let validationRejectMessage = kickBoxRejectMsg.length
        ? validations.email.concat('. Did you mean: ', kickBoxRejectMsg)
        : validations.email
      if (validationRejectMessage) {
        setInvalidMessage(validationRejectMessage)
        setIsProcess(false)
        return false
      }
    }
    return true
  }

  const handleSubmit = async e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    try {
      const validEmail = await isValidEmailKickbox(email)
      if (!validEmail) return

      const { userId, status } = await checkUserExist(email)
      if (userId) {
        // if the user exist then
        // * Trigger User Updated event
        // update baName in BE
        const payload = { sephoraStoreId: storeId }
        await dispatch(patchStoreFieldsUser(email, payload))
        // * trigger Identify call with storeId
        // identify user
        const user = {
          id: userId,
          sephoraStoreId: storeId,
          info: { email: email, doubleOptInEmail: doubleOptInEmailValue }
        }
        // it use a Identify Call for an empty user because the user is NOT logged in
        await identifyEmptyUser(user)
      } else {
        // create empty user
        const payload = {
          userInfo: {
            email: email,
            doubleOptInEmail: doubleOptInEmailValue
          },
          sephoraStoreId: storeId
        }
        const { user } = await dispatch(saveEmptyUser(payload))
        // identify user
        if (storeId) user.sephoraStoreId = storeId
        await identifyEmptyUser(user)
      }
      if (onSubmit) {
        onSubmit(email, status)
      }
    } catch (err) {
      console.log('error ', err)
    }

    // track CTA click
    trackEvent('submit', { CTA: ctaText, variant, questionType: 'email' })
  }

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      handleSubmit(event)
    }
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        mx: 2,
        top: '50%',
        transform: 'translateY(20%)'
      }}
    >
      <Box
        sx={{
          px: 2,
          pt: 2,
          border: 1,
          borderRadius: 1,
          borderColor: 'gray.lightGray',
          backgroundColor: 'gray.white'
        }}
      >
        <Typography
          variant="h4"
          sx={{
            mb: 2
          }}
        >
          {title}
        </Typography>
        <TextField
          fullWidth
          onKeyPress={onKeyPress}
          onChange={handleEmailChange}
          name="email"
          placeholder="Email"
          autoComplete="email"
          value={email}
          error={invalidMessage}
          helperText={invalidMessage}
          sx={{
            mb: 1,
            borderRadius: 1,
            '.MuiInputBase-root': {
              backgroundColor: ['unset', 'unset', 'gray.white'] // only add background to input tag
            },
            '.MuiFormHelperText-root': {
              backgroundColor: 'transparent !important'
            }
          }}
        />
        <Button
          onClick={handleSubmit}
          data-cy={`${DATA_CY_PREFIX}-email-me-my-quiz`}
          data-testid={`${DATA_CY_PREFIX}-email-me-my-quiz`}
          variant="contained"
          color="secondary"
          fullWidth
          sx={{ mb: 2 }}
          type="submit"
          disabled={isProcess}
        >
          {ctaText}
        </Button>
        <Stack
          direction="row"
          sx={{
            backgroundColor: 'gray.lightBlue',
            mx: -2,
            p: 2,
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px'
          }}
        >
          <Stack
            justifyContent="center"
            sx={{
              pr: 2
            }}
          >
            <img
              style={{ width: 99, height: 28 }}
              src={`${CDN_IMG}sephora/instore-beauty-insider-logo-grey.svg`}
              alt="Proven"
            />
          </Stack>
          <Typography variant="footnoteLight">
            Use your Beauty Insider account email to earn points on PROVEN.
          </Typography>
        </Stack>
      </Box>

      <Box sx={{ mt: 2 }}>
        <FormControlLabel
          sx={{
            alignItems: 'flex-start'
          }}
          control={
            <Checkbox
              sx={{ pt: 0 }}
              checked={doubleOptInEmailValue}
              onChange={onChangeDoubleOptInEmail}
            />
          }
          label={
            <ContainerTocRestyled>
              <span dangerouslySetInnerHTML={{ __html: toc }}></span>
            </ContainerTocRestyled>
          }
        />
      </Box>
    </Box>
  )
}

EnterYourEmailContainer.propTypes = {}

export default EnterYourEmailContainer
