import React from 'react'
import { Typography, Box } from '@mui/material'
import propTypes from 'prop-types'
import { onDesktop } from '../../../styles/theme-proven/responsive'
import { SELECT_MULTIPLE_CHECK_BOX_WITH_SIDEBAR_STYLE } from '../../quiz/inputs/select-multiple'
import useWidth from '../../../hooks/useWidth'
import Checkbox from '@mui/material/Checkbox'
import { QUESTION_STYLE } from '../../../constants/configs/questionnaire'

function ProvenMultipleQuizSelectorDefault({
  onClick,
  selected,
  label,
  subline,
  questionStyle,
  isMobile
}) {
  const buildContainerStyles = questionStyle => {
    let sx = {
      borderRadius: 1,
      background: selected ? '#F0F7FA' : 'white',
      px: 2,
      py: 1.5,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
      justifyContent: 'center',
      height: { xs: '44px', md: '74px' },
      border: selected ? '1px solid #256376' : '1px solid #ACB1B3',
      '&: hover': {
        [onDesktop]: {
          border: '1px solid #256376'
        }
      }
    }

    switch (questionStyle) {
      case QUESTION_STYLE.SELECT_MULTIPLE_LONG:
        sx = isMobile ? { ...sx, height: 'unset', p: 1.5, width: '100%', alignItems: 'center' } : sx
        break
    }

    return sx
  }

  const getLabelTypography = (questionStyle = QUESTION_STYLE.SELECT_MULTIPLE_DOUBLE) => {
    const DESKTOP = `MOBILE:${false}_`
    const MOBILE = `MOBILE:${true}_`
    const mobilePrefixAndQuestionStyle = `MOBILE:${Boolean(isMobile)}_${questionStyle}`

    switch (mobilePrefixAndQuestionStyle) {
      case MOBILE + QUESTION_STYLE.SELECT_MULTIPLE_LONG:
        return { variant: 'footnote', color: 'gray.elysian', selectedColor: 'primary.main' }

      case DESKTOP + QUESTION_STYLE.SELECT_MULTIPLE_LONG:
        return { variant: 'body2', color: 'gray.elysian', selectedColor: 'primary.main' }

      case DESKTOP + QUESTION_STYLE.SELECT_MULTIPLE_SHORT:
      case MOBILE + QUESTION_STYLE.SELECT_MULTIPLE_SHORT:
      case DESKTOP + QUESTION_STYLE.SELECT_MULTIPLE_STANDARD:
      case MOBILE + QUESTION_STYLE.SELECT_MULTIPLE_STANDARD:
      case DESKTOP + QUESTION_STYLE.SELECT_MULTIPLE_DOUBLE:
      case MOBILE + QUESTION_STYLE.SELECT_MULTIPLE_DOUBLE:
      default:
        return { variant: 'body2', color: 'gray.elysian', selectedColor: 'primary' }
    }
  }

  const boxSx = buildContainerStyles(questionStyle)
  const { variant, color, selectedColor } = getLabelTypography(questionStyle)

  return (
    <Box onClick={onClick} sx={boxSx}>
      <Typography variant={variant} color={selected ? selectedColor : color}>
        {label}
      </Typography>
      {!!subline && (
        <Typography variant="footnote" color="gray.darkGray">
          {subline}
        </Typography>
      )}
    </Box>
  )
}

function ProvenMultipleQuizSelectorCheckBoxStyle({ onClick, selected, label, subline }) {
  const width = useWidth()
  const isMobile = width === 'xs' || width === 'sm'

  return (
    <Box
      onClick={onClick}
      sx={{
        borderRadius: 1,
        background: selected ? '#D6EBF2' : '#FFFFFF',
        px: 2,
        py: 1.5,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        textAlign: 'center',
        height: { xs: '56px', md: 'auto' },
        minHeight: { xs: '56px', md: '46px' },
        width: { xs: '100%', md: '46%' },
        border: selected ? '1px solid #D6EBF2' : '1px solid #ACB1B3',
        '& .MuiCheckbox-root': {
          pl: 0
        },
        '&: hover': {
          [onDesktop]: {
            border: '1px solid #ACB1B3'
          }
        }
      }}
    >
      <Checkbox checked={!!selected} label={label} />
      <Typography
        variant="body2"
        color={selected ? '#303C42' : '#5D6469'}
        sx={{
          textAlign: 'left',
          overflowWrap: 'anywhere'
        }}
      >
        {label}
      </Typography>
      {!!subline && <Typography>{subline}</Typography>}
    </Box>
  )
}

const ProvenMultipleQuizSelector = ({
  label,
  subline,
  onChange,
  selected,
  value,
  multipleSelectorStyle,
  questionStyle,
  isMobile
}) => {
  switch (multipleSelectorStyle) {
    case SELECT_MULTIPLE_CHECK_BOX_WITH_SIDEBAR_STYLE:
      return (
        <ProvenMultipleQuizSelectorCheckBoxStyle
          onClick={() => onChange({ value, checked: !selected })}
          selected={selected}
          subline={subline}
          label={label}
        />
      )
    default:
      return (
        <ProvenMultipleQuizSelectorDefault
          onClick={() => onChange({ value, checked: !selected })}
          selected={selected}
          subline={subline}
          label={label}
          isMobile={isMobile}
          questionStyle={questionStyle}
        />
      )
  }
}

ProvenMultipleQuizSelector.propTypes = {
  label: propTypes.string.isRequired,
  subline: propTypes.string,
  name: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  selected: propTypes.bool,
  value: propTypes.number,
  multipleSelectorStyle: propTypes.string
}

export default ProvenMultipleQuizSelector
