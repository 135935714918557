import React from 'react'
import propTypes from 'prop-types'
import { GOOGLE_PLACES_API_KEY } from '../../../constants/configs/google-places'
import { BUILDER_CDN } from '../../../constants/endpoints'
import { Box, Paper, Stack, Typography } from '@mui/material'
import { createProvenTheme } from '../../../styles/theme-proven'
import ProvenIcon from '../proven-icon'
import { getCountryByCode } from '@provenai/shared'
import { Image } from 'components/builder/blocks/shared/Image'

const themeProven = createProvenTheme()
const MAX_GOOGLE_MAP_RESOLUTION = 640
const GOOGLE_MAP_GREY_STYLE =
  'style=element:geometry.fill|color:0xe3e8ec&style=element:geometry.stroke|color:0xb6bdc3&style=element:labels.icon|visibility:off&style=feature:road|color:0xffffff'

//TODO on ENG-694 when we rebrand congrats/eye, replace congrats/eye map (src/components/account/congratulationsEyeAB/B/eye-cream-duo/duo-ingredients/env-map/index.js) by ProvenMap and add needed props according to the congrats/eye design
function ProvenMap({ countryCode, zipCode, state, googleMapWidth, googleMapHeight }) {
  const { name: countryName } = getCountryByCode(countryCode)
  const locationEncoded = encodeURI(`${countryName}, ${zipCode}`)

  //TODO on ENG-693 make map can be also centered properly for other countries than the US
  const mapImageUrl = zipCode
    ? `https://maps.googleapis.com/maps/api/staticmap?center=${locationEncoded}&color:0x578595|${encodeURI(
        zipCode
      )}&zoom=13&${GOOGLE_MAP_GREY_STYLE}&key=${GOOGLE_PLACES_API_KEY}`
    : ''

  let googleMapWidthLimited = googleMapWidth
  let googleMapHeightLimited = googleMapHeight
  if (googleMapWidth > MAX_GOOGLE_MAP_RESOLUTION && googleMapHeight < MAX_GOOGLE_MAP_RESOLUTION) {
    googleMapWidthLimited = MAX_GOOGLE_MAP_RESOLUTION
    googleMapHeightLimited = (MAX_GOOGLE_MAP_RESOLUTION * googleMapHeight) / googleMapWidth
  } else if (
    googleMapHeight > MAX_GOOGLE_MAP_RESOLUTION &&
    googleMapWidth < MAX_GOOGLE_MAP_RESOLUTION
  ) {
    googleMapHeightLimited = MAX_GOOGLE_MAP_RESOLUTION
    googleMapWidthLimited = (googleMapWidth * MAX_GOOGLE_MAP_RESOLUTION) / googleMapHeight
  } else if (
    googleMapHeight > MAX_GOOGLE_MAP_RESOLUTION &&
    googleMapWidth > MAX_GOOGLE_MAP_RESOLUTION
  ) {
    googleMapHeightLimited =
      googleMapHeight >= googleMapWidth
        ? MAX_GOOGLE_MAP_RESOLUTION
        : (MAX_GOOGLE_MAP_RESOLUTION * googleMapHeight) / googleMapWidth
    googleMapWidthLimited =
      googleMapWidth >= googleMapHeight
        ? MAX_GOOGLE_MAP_RESOLUTION
        : (googleMapWidth * MAX_GOOGLE_MAP_RESOLUTION) / googleMapHeight
  }

  const mapBackgroundImageUrl = `${mapImageUrl}&size=${parseInt(googleMapWidthLimited)}x${parseInt(
    googleMapHeightLimited
  )}`

  return (
    <Paper
      sx={{
        display: 'flex',
        width: '100%',
        // width: { xs: '100%', lg: '63.48vw' },
        height: { xs: themeProven.spacing(25.75), lg: themeProven.spacing(45.5) },
        background: `url(${zipCode ? mapBackgroundImageUrl : ''}) top left no-repeat`,
        backgroundSize: 'cover',
        backgroundPosition: `top 0 left 0`,
        boxShadow: 'unset',
        borderRadius: 'unset'
      }}
    >
      <Box
        width="100%"
        position="relative"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-end"
      >
        <Box
          sx={{
            width: { xs: themeProven.spacing(11.5), lg: themeProven.spacing(19.25) },
            height: { xs: themeProven.spacing(11.5), lg: themeProven.spacing(19.25) },
            position: 'absolute',
            top: '50%',
            transform: {
              xs: `translateY(${themeProven.spacing(-5.75)}) translateX(${themeProven.spacing(
                -5.75
              )})`,
              lg: `translateY(${themeProven.spacing(-9.625)}) translateX(${themeProven.spacing(
                -9.625
              )})`
            },
            left: { xs: '50%', lg: '53.3%' }
          }}
        >
          <Image
            lazy
            image={`${BUILDER_CDN}9be7b3a343374be9b104de2868ae6f69/ca27a10aa5db4db4af0b9195a9f231f4`}
            aspectRatio={1}
          />
        </Box>

        <Stack
          position="absolute"
          zIndex={4}
          width="fit-content"
          px={1.5}
          py={1}
          sx={{
            backgroundColor: themeProven.palette.gray.white,
            boxShadow: themeProven.shadows[2],
            borderRadius: 1,
            left: { xs: themeProven.spacing(2), lg: 'unset' },
            top: { xs: themeProven.spacing(2), lg: themeProven.spacing(3) },
            alignItems: { xs: 'flex-start', lg: 'flex-end' },
            right: { lg: themeProven.spacing(1) }
          }}
        >
          {countryCode === 'US' && state && (
            <Typography variant="title2" color="primary.main" mb={0.5}>
              {state}
            </Typography>
          )}
          {countryCode !== 'US' && (
            <Typography variant="title2" color="primary.main">
              {countryName}
            </Typography>
          )}
          <Typography
            variant="body2"
            color="gray.darkGray"
            sx={{ display: 'flex', alignItems: 'center', gap: 0.75 }}
          >
            <ProvenIcon type="system" name="location" color="gray.darkGray" size="medium" />
            {countryCode === 'US' ? `ZIP ${zipCode}` : zipCode}
          </Typography>
        </Stack>

        <Paper
          sx={{
            position: 'absolute',
            width: '100%',
            height: { xs: '20%', lg: '22.5%' },
            mt: { xs: '-1.5%', lg: '-2%' },
            top: 0,
            background: {
              xs: 'linear-gradient(179deg, #FFFFFF 11.07%, rgba(255, 255, 255, 0) 60.32%)',
              md: 'linear-gradient(2deg, #FFFFFF 26.69%, rgba(255, 255, 255, 0) 92%);'
            },
            transform: {
              xs: 'matrix(-1, 0, 0, 1, 0, 0)',
              md: 'rotate(180deg)',
              lg: 'rotate(-180deg)'
            },
            boxShadow: 'unset',
            borderRadius: 'unset',
            mixBlendMode: 'normal'
          }}
        />

        <Paper
          sx={{
            mt: 'auto',
            mb: '-0.5%',
            width: `100%`,
            height: { xs: '28%', lg: '40.5%' },
            background: {
              xs: 'linear-gradient(180deg, #FFFFFF 22.17%, rgba(255, 255, 255, 0) 78.08%)',
              lg: 'linear-gradient(180deg, #FFFFFF 11.17%, rgba(255, 255, 255, 0) 79.28%)'
            },
            boxShadow: 'unset',
            borderRadius: 'unset',
            mixBlendMode: 'normal',
            transform: 'matrix(1, 0, 0, -1, 0, 0)'
          }}
        />
      </Box>
    </Paper>
  )
}

ProvenMap.propTypes = {
  zipCode: propTypes.string.isRequired,
  countryCode: propTypes.string.isRequired,
  state: propTypes.string,
  googleMapWidth: propTypes.number.isRequired,
  googleMapHeight: propTypes.number.isRequired
}

export default ProvenMap
