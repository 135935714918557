import React from 'react'
import propTypes from 'prop-types'
import PageWrapper from '../page-wrapper'
import { ThemeProvider, useTheme } from '@mui/material/styles'
import { createProvenTheme } from '../../../styles/theme-proven'
import ProvenResponsiveContainer from '../proven-responsive-container'

/**
 * ProvenPage is a page wrapper that handles all SEO properties,
 * and provides a content container with the outer horizontal margins defined in our style guide.
 *
 * It must be used for all new pages that are based on our custom Proven Theme.
 *
 * @param children
 * @param withoutProvenResponsiveContainer when it is true, children is not wrapped with a ProvenResponsiveContainer
 * @param any SEO props that PageWrapper component accepts
 * @returns {JSX.Element}
 */

const ProvenPage = ({
  children,
  background,
  themeName,
  withoutProvenResponsiveContainer,
  ...props
}) => {
  let theme = useTheme()
  const currentThemeIsBrandingV4Theme = theme?.palette?.primary?.darkBlue != undefined //To avoid the default theme overwrites the new branding theme (darkBlue is a new color so we use it to check if it is the new theme)
  if (!currentThemeIsBrandingV4Theme) theme = createProvenTheme(themeName)
  return (
    <ThemeProvider theme={theme}>
      <PageWrapper {...props} background={background ? background : theme.palette.gray.white}>
        {withoutProvenResponsiveContainer ? (
          children
        ) : (
          <ProvenResponsiveContainer minHeight="100dvh">{children}</ProvenResponsiveContainer>
        )}
      </PageWrapper>
    </ThemeProvider>
  )
}

ProvenPage.defaultProps = {
  withoutProvenResponsiveContainer: false
}

ProvenPage.propTypes = {
  children: propTypes.node.isRequired,
  withoutProvenResponsiveContainer: propTypes.bool,
  themeName: propTypes.string
}

export default ProvenPage
