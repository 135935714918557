import React from 'react'
import { Typography, Box } from '@mui/material'
import propTypes from 'prop-types'
import { onDesktop } from '../../../styles/theme-proven/responsive'
import useWidth from '../../../hooks/useWidth'

export const quizIcons = {
  Acne: 'https://dl7bo1dy930sf.cloudfront.net/img/icons/icon-kit-concern-acne.svg',
  Dryness: 'https://dl7bo1dy930sf.cloudfront.net/img/icons/icon-kit-concern-dryness.svg',
  'Fine lines or wrinkles':
    'https://dl7bo1dy930sf.cloudfront.net/img/icons/icon-kit-concern-finelines-wrinkles.svg',
  'Fine Lines and Wrinkles':
    'https://dl7bo1dy930sf.cloudfront.net/img/icons/icon-kit-concern-eye-finelines-wrinkles.svg',
  Hyperpigmentation:
    'https://dl7bo1dy930sf.cloudfront.net/img/icons/icon-kit-concern-hyperpigmentation.svg',
  'Loss of firmness or elasticity':
    'https://dl7bo1dy930sf.cloudfront.net/img/icons/icon-kit-concern-loss-of-firmness.svg',
  Other: 'https://dl7bo1dy930sf.cloudfront.net/img/icons/icon-kit-concern-other.svg',
  Redness: 'https://dl7bo1dy930sf.cloudfront.net/img/icons/icon-kit-concern-redness.svg',
  Sensitivity: 'https://dl7bo1dy930sf.cloudfront.net/img/icons/icon-kit-concern-sensitivity.svg',
  'Dark Circles':
    'https://dl7bo1dy930sf.cloudfront.net/img/icons/icon-kit-concern-eye-dark-circle.svg',
  'Drooping or Loss of Elasticity':
    'https://dl7bo1dy930sf.cloudfront.net/img/icons/icon-kit-concern-eye-drooping.svg',
  'icon-kit-concern-eye-finelines-wrinkles':
    'https://dl7bo1dy930sf.cloudfront.net/img/icons/icon-kit-concern-eye-finelines-wrinkles.svg',
  'Puffiness or Eye Bags':
    'https://dl7bo1dy930sf.cloudfront.net/img/icons/icon-kit-concern-eye-puffiness.svg',
  'Sensitivity or Redness':
    'https://dl7bo1dy930sf.cloudfront.net/img/icons/icon-kit-concern-eye-sensitivity.svg',
  'Uneven Skin Tone':
    'https://dl7bo1dy930sf.cloudfront.net/img/icons/icon-kit-concern-eye-uneven-skin-tone.svg'
}

const ConcernMultipleQuizSelector = ({ label, onChange, selected, value, subline }) => {
  const width = useWidth()
  const isMobile = width === 'xs' || width === 'sm'
  const buildConcernStyle = () => {
    return {
      borderRadius: 1,
      background: selected ? '#F0F7FA' : 'white',
      px: 2,
      py: 1.5,
      flex: 1,
      cursor: 'pointer',
      border: selected ? '1px solid #256376' : '1px solid #ACB1B3',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: '4px',
      '&: hover': {
        [onDesktop]: {
          border: '1px solid #256376'
        }
      }
    }
  }

  const concernStyleBox = buildConcernStyle()

  return (
    <Box onClick={() => onChange({ value, checked: !selected })} sx={concernStyleBox}>
      {quizIcons[label] && (
        <Box component="img" src={quizIcons[label]} width="32px" height="32px" />
      )}
      <Box
        sx={{
          minHeight: { xs: '34px', md: '48px' },
          display: 'flex',
          alignItems: 'center',
          flex: 1
        }}
      >
        <Typography variant="body2" color={selected ? 'primary' : 'gray.elysian'}>
          {!isMobile && label === 'Hyperpigmentation' ? 'Hyper-pigmentation' : label}
        </Typography>
        {!!subline && <Typography>{subline}</Typography>}
      </Box>
    </Box>
  )
}

ConcernMultipleQuizSelector.propTypes = {
  label: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  selected: propTypes.bool,
  value: propTypes.number,
  subline: propTypes.string,
  multipleSelectorStyle: propTypes.string
}

export default ConcernMultipleQuizSelector
